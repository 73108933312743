import React from "react"
import { Layout, List } from "antd"
import "./VideoAttachmentList.less"
import {
  DownloadOutlined,
  FileOutlined,
  FileProtectOutlined,
} from "@ant-design/icons"
import { getDownloadUrl } from "../utils"
import Avatar from "antd/es/avatar/avatar"

const { Content } = Layout

const getIconByExtension = ext => {
  return <FileOutlined />
}

const VideoAttachmentList = ({ data }) => {
  const renderItem = (item, index) => {
    const { name, file_id, attachment } = item
    const { filename, extension, s3_key } = attachment
    const downloadUrl = getDownloadUrl(s3_key)
    return (
      <List.Item
        key={index}
        actions={[
          <a href={downloadUrl} target="_blank">
            <DownloadOutlined />
          </a>,
        ]}
      >
        <List.Item.Meta
          avatar={<Avatar size={32} icon={<FileProtectOutlined />} />}
          title={
            <a href={downloadUrl} target="_blank">
              {attachment.filename}
            </a>
          }
        />
      </List.Item>
    )
  }

  return (
    <div className="video-attachment-list-wrapper">
      <List dataSource={data} renderItem={renderItem} />
    </div>
  )
}

export default VideoAttachmentList
