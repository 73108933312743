import React, { useEffect, useMemo, useState } from "react"
import "./VideoDetailHeader.less"
import { Affix, Anchor, Col, Progress, Row, Skeleton, Space } from "antd"
import PropTypes from "prop-types"
import { defaultTheme } from "../theme"
import { FavoriteButton, PlayButton, PurchaseButton } from "./Buttons"
import { AuthorList, Price } from "./Labels"
import HeaderExtra from "./HeaderExtra"
import { useTranslation } from "react-i18next"
import { COMING_SOON } from "../enums/RecordStates"
import ComingSoon from "./ComingSoon"
import ContainerWithPadding from "./ContainerWithPadding"

const VideoDetailHeader = ({
                             data,
                             canPurchase,
                             loading,
                             canPlay,
                             isFavorite,
                             onToggleFavorite,
                             onPurchase,
                             onPlay,
                             showFavoriteButton,
                             progress,
                             showAnchor
                           }) => {
  const {
    id,
    currency,
    description,
    duration,
    header_image,
    landscape,
    price,
    price_policy,
    special_price,
    short_description,
    state,
    subtitle,
    title,
    wallpaper,
    author_rels,
    category_rels,
    available_date
  } = data
  const [internalFavorite, setInternalFavorite] = useState(isFavorite)
  useEffect(() => {
    setInternalFavorite(isFavorite)
  }, [isFavorite])

  const { t } = useTranslation()

  const videoActionButtons = useMemo(() => {
    if (loading) {
      return <Skeleton active />
    }

    if (state === COMING_SOON) {
      return <ComingSoon availableDate={available_date} />
    }

    return (
      <>
        <Space align={"start"}>
          {canPlay && (
            <PlayButton
              onClick={() => {
                onPlay(data)
              }}
            />
          )}
          {canPurchase && (
            <PurchaseButton
              price={price}
              pricePolicy={price_policy}
              specialPrice={special_price}
              currency={currency}
              onClick={() => {
                onPurchase(data)
              }}
            />
          )}
          {showFavoriteButton && (
            <FavoriteButton
              onClick={() => {
                onToggleFavorite(data)
              }}
              isFavorite={isFavorite}
            />
          )}
        </Space>
      </>
    )
  }, [
    loading,
    progress,
    canPlay,
    canPurchase,
    isFavorite,
    price_policy,
    available_date,
    state
  ])

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , url(${
          wallpaper?.localFile?.childImageSharp.fixed.src ||
          defaultTheme.defaultWallpaper
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}
    >
      <ContainerWithPadding align={"start"} size={"large"}>
        <div className="detail-wrapper">
          <Row gutter={60}>
            <Col xs={24}
                 sm={24}
                 md={24}
                 lg={16}
                 xl={18}
                 xxl={{ span: 16, offset: 2 }}>
              <div className={`recap-wrapper content dark light`}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <div>
                      <div className={"wrapper-title-image"}>
                        {header_image ? (
                          <img src={header_image?.localFile?.publicURL} alt={title} />
                        ) : (
                          <h1>{title}</h1>
                        )}
                      </div>
                      <Progress percent={progress} showInfo={false} />
                      <div className={"wrapper-title-info"}>
                        {videoActionButtons}
                      </div>
                    </div>
                  </Col>
                </Row>
                <h1 className="title">{title}</h1>
                <h2 className="subtitle">{subtitle}</h2>
                <p className="description">{short_description}</p>
                <Space direction="horizontal">
                  <div className="each-item">
                    <AuthorList authorList={[]} />
                  </div>
                  <div className="each-item">
                    <Price
                      price={price}
                      pricePolicy={price_policy}
                      currency={currency}
                    />
                  </div>
                </Space>
                <HeaderExtra />
              </div>
            </Col>
            <Col sm={24} xs={24} md={24} lg={8} xl={6} xxl={6}>
              {showAnchor && (
                <Affix className="affix-wrapper">
                  <div className="side-nav-wrapper">
                    <div className="single-action">
                      <Space direction="vertical">
                        {canPlay && (
                          <PlayButton
                            onClick={() => {
                              onPlay && onPlay(data)
                            }}
                          />
                        )}
                        {canPurchase && (
                          <PurchaseButton
                            price={price}
                            pricePolicy={price_policy}
                            specialPrice={special_price}
                            onClick={() => {
                              onPurchase && onPurchase(data)
                            }}
                          />
                        )}
                      </Space>
                    </div>
                    <Anchor className="hookers">
                      <Anchor.Link href="#intro" title={t("label:intro")} />
                      <Anchor.Link
                        href="#description"
                        title={t("label:description")}
                      />
                      <Anchor.Link
                        href="#learning"
                        title={t("label:whatGoingToLearn")}
                      />
                      <Anchor.Link
                        href="#materials"
                        title={t("label:materials")}
                      />
                      <Anchor.Link href="#authors" title={t("label:authors")} />
                      <Anchor.Link href="#faq" title={t("label:faq")} />
                      <Anchor.Link
                        href="#certification"
                        title={t("label:certification")}
                      />
                    </Anchor>
                  </div>
                </Affix>
              )}
            </Col>
          </Row>
        </div>
      </ContainerWithPadding>
    </div>
  )
}

VideoDetailHeader.propTypes = {
  data: PropTypes.object.isRequired,
  canPurchase: PropTypes.bool,
  loading: PropTypes.bool,
  canPlay: PropTypes.bool,
  isFavorite: PropTypes.bool,
  onToggleFavorite: PropTypes.func.isRequired,
  onPurchase: PropTypes.func.isRequired,
  showFavoriteButton: PropTypes.bool,
  onPlay: PropTypes.func,
  progress: PropTypes.number
}

VideoDetailHeader.defaultProps = {
  onToggleFavorite: () => {
  },
  onPurchase: () => {
  },
  onPlay: () => {
  },
  showAnchor: true
}

export default VideoDetailHeader
