import React, { useEffect } from "react"
import VideoDetailHeader from "../components/VideoDetailHeader"
import PageRoot from "../components/PageRoot"
import withOwnershipCheck from "../components/withOwnershipCheck"
import { getImageUrl, sendViewItemEvent } from "../utils"
import ContainerWithPadding from "../components/ContainerWithPadding"
import ReadOnlyQuill from "../components/ReadOnlyQuill"
import { Col, Row } from "antd"
import Faq from "../components/Faq"
import { useTranslation } from "react-i18next"
import VideoAttachmentList from "../components/VideoAttachmentList"
import AuthorListDetail from "../components/AuthorListDetail"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"

const VideoDetailHeaderWithCheck = withOwnershipCheck(VideoDetailHeader)

const VideoDetailPage = ({ pageContext }) => {
  const { t } = useTranslation()
  const { video } = pageContext
  const {
    title,
    short_description,
    landscape,
    description,
    attachments,
    show_faq,
    faq,
    faq_json,
    show_learning_path,
    learning_path,
    author_rels,
    seo
  } = video

  const seoImage = getImageUrl(landscape, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  useEffect(() => {
    sendViewItemEvent(video)
  }, [])

  return (
    <PageRoot
      title={seo.title}
      description={seo.description}
      image={process.env.GATSBY_AUTH0_REDIRECT_URI + seoImage}
      type={"video.episode"}
    >
      <div id="intro">
        <VideoDetailHeaderWithCheck data={video} />
      </div>
      <ContainerWithPadding size={"large"} align={"start"}>
        <Row gutter={26} className="data-video-container">
          <Col xs={24}
               sm={24}
               md={24}
               lg={16}
               xl={18}
               xxl={{ span: 16, offset: 2 }}>
            <div id="description" className="row-info">
              <h2>{t("label:videoDescription")}</h2>
              <hr />
              <ReadOnlyQuill value={description} />
            </div>

            {show_learning_path && (
              <div id="learning" className="row-info">
                <h2>{t("label:whatGoingToLearn")}</h2>
                <hr />
                <Row gutter={26}>
                  <Col sx={24} sm={24} md={12}>
                    <GatsbyImage
                      image={getImage(landscape?.localFile)}
                      alt={title || "cover"}
                    />
                  </Col>
                  <Col sx={24} sm={24} md={12}>
                    <ReadOnlyQuill value={learning_path} />
                  </Col>
                </Row>
              </div>
            )}

            <div id="authors" className="row-info">
              <h2>{t("label:authors")}</h2>
              <hr />
              <AuthorListDetail data={author_rels.map(rel => rel.author)} />
            </div>

            {attachments?.length > 0 && (
              <div id="materials" className="row-info">
                <h2>Moduli e materiali</h2>
                <VideoAttachmentList data={attachments} />
              </div>
            )}

            {show_faq && (
              <div id="faq" className="row-info">
                <h2>{t("label:faq")}</h2>
                <hr />
                {/*<ReadOnlyQuill value={faq}/>*/}
                <Faq data={faq_json} />
              </div>
            )}
            {/*<div id="certification" className="row-info">*/}
            {/*    <h2>Certificazione online</h2>*/}
            {/*    <hr/>*/}
            {/*</div>*/}
          </Col>
        </Row>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default VideoDetailPage
